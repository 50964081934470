import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {CrearCarpetaFG} from "./modal-crear-carpeta.component";
import {Carpeta} from "../../interfaces/carpeta";

@Injectable({
  providedIn: 'root'
})
export class CrearCarpetaService {

  private readonly carpetaUrl: string;

  constructor(private httpClient: HttpClient) {
    this.carpetaUrl = environment.apiUrl + '/shared/carpeta';
  }

  public __invoke(parametros: CrearCarpetaFG): Observable<Carpeta> {
    return this.httpClient.post<Carpeta>(this.carpetaUrl, parametros);
  }
}

<div class="fixed z-30 inset-0 bg-black opacity-50"
     *ngIf="panelLateralService.isOpen$ | async"
     (click)="panelLateralService.toggle()"></div>

<div class="fixed z-40 h-full pt-4 overflow-y-auto bg-white right-0 top-0 border-l-2 w-vista-previa"
     [ngClass]="(panelLateralService.isOpen$ | async) ? 'open' : 'side-panel'">
  <div class="border-b-2 flex justify-between items-center">
    <h5 id="drawer-right-label" class="mb-4 font-semibold text-2xl ml-3">
      {{ titulo }}
    </h5>
    <div (click)="panelLateralService.toggle()"
            class=" cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 me-3 -mt-1.5">
      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
      </svg>
      <span class="sr-only"></span>
    </div>
  </div>
  <div>
    <ng-template #container></ng-template>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  ComponentRef,
  HostListener,
  Type,
  ViewChild,
  ViewContainerRef,
  ChangeDetectorRef,
  Output, EventEmitter
} from '@angular/core';
import {ModalService} from "./modal.service";
import {Options} from '../../interfaces/modal';
import {DeteccionCambiosService} from "../../../../infrastructure/services/deteccion-cambios.service";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('container', {read: ViewContainerRef, static: true}) container!: ViewContainerRef;

  public titulo = '';
  protected options: Options = {size: 'sm:my-8 w-full sm:max-w-lg sm:p-6'};
  @Output() cargado = new EventEmitter<boolean>();

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.modalService.close(false);
    }
  }

  public constructor(public modalService: ModalService, private cdr: ChangeDetectorRef, private detectaUIService: DeteccionCambiosService ) {
  }

  public ngOnInit(): void {
    this.modalService.setModalComponentRef(this);
  }

  public ngAfterViewInit() {
    this.modalService.componentToLoad$.subscribe({
      next: (component) => this.loadComponent(component)
    });
  }

  public loadComponent(component: Type<any>, options?: Options): ComponentRef<any> {
    this.container.clear();
    const componentRef = this.container.createComponent(component);
    this.options = {size: 'sm:my-8 w-full sm:max-w-lg sm:p-6'};
    if (options) {
      this.options = options;
    }
    this.modalService.open();
    this.detectaUIService.getActualizaUI$().subscribe(() => {
      this.cdr.markForCheck();
      setTimeout(() => {
        this.cargado.emit(true);
      });
    });
    return componentRef;
  }
}

import {Subject} from 'rxjs';
import {Component, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.css']
})

export abstract class ModalBaseComponent {
  @Inject(DOCUMENT) private document = {} as Document;

  protected identificadorModal: string = 'modal';

  public actionConfirmedSubject: Subject<any> = new Subject<any>();

  public openModal(): void {
    this.actionConfirmedSubject = new Subject<boolean>();
    this.inicializar();
    this.toggleModal();
  }

  public closeModal(): void {
    this.toggleModal();
  }

  public toggleModal(): void {
    const modal = document.getElementById(this.identificadorModal);
    document.body.appendChild(modal!);
    modal?.classList.toggle('hidden');
  }

  public confirmAction(data?: any): void {
    this.actionConfirmedSubject.next(data ? data : true);
    this.closeModal();
  }

  protected inicializar(): void {

  }

  public removeModal(): void {
    const modal = document.getElementById(this.identificadorModal);
    if (modal) {
      modal.remove();
    }
  }
}

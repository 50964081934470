import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingButtonDirective} from '../../../../infrastructure/directives/loading-button.directive';
import {GrupoBotonesModule} from '../grupo-botones/grupo-botones.module';
import {ModalCrearCarpetaComponent} from "./modal-crear-carpeta.component";



@NgModule({
  declarations: [
    ModalCrearCarpetaComponent,
  ],
  exports: [
    ModalCrearCarpetaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoadingButtonDirective,
    GrupoBotonesModule
  ]
})
export class ModalCrearCarpetaModule { }

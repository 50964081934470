import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ConfiguracionMsal} from '../interfaces/configuracion-msal';

@Injectable({
  providedIn: 'root'
})
export class ObtenerConfiguracionMsalService {

  public readonly publicasUrl: string;
  public configuracionMSAL!: ConfiguracionMsal;

  private httpClient: HttpClient;

  constructor(private readonly httpHandler: HttpBackend) {
    this.httpClient = new HttpClient(httpHandler);
    this.publicasUrl = environment.apiUrl + '/guest/configuracion-cliente/msal';
  }

  public __invoke(): Promise<ConfiguracionMsal> {
    return new Promise<ConfiguracionMsal>((resolve, reject) => {
      this.httpClient.get<ConfiguracionMsal>(this.publicasUrl).subscribe({
        next: (configuracionMsal: ConfiguracionMsal) => {
          this.configuracionMSAL = configuracionMsal;
          resolve(configuracionMsal);
        }, error: (errorResponse) => {
          reject(errorResponse);
        }
      });
    });
  }

  public obtenerValor(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return undefined;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    return key.reduce((acc: any, current: string) => acc && acc[current], this.configuracionMSAL);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ElementoMenu} from './elemento-menu';

@Injectable({
  providedIn: 'root'
})
export class ObtenerMenuService {
  private readonly sharedUrl: string;

  constructor(private httpclient: HttpClient) {
    this.sharedUrl = environment.apiUrl + '/shared/menu';
  }

  public __invoke(): Observable<ElementoMenu[]> {
    return this.httpclient.get<ElementoMenu[]>(this.sharedUrl);
  }
}

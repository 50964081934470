import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObtenerImagenPerfilService {

  constructor(private httpclient: HttpClient) {

  }

  public __invoke(accountId: string): Observable<any> {
    return this.httpclient.get('https://graph.microsoft.com/v1.0/me/photo/$value', {responseType: 'blob'});
  }
}

import {Injectable, Type} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {PanelLateralComponent} from "./panel-lateral.component";
import {DeteccionCambiosService} from "../../../../infrastructure/services/deteccion-cambios.service";

@Injectable({
  providedIn: 'root'
})

export class PanelLateralService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  public isOpen$ = this.isOpenSubject.asObservable();

  private _loadComponent = new Subject<Type<any>>();
  public componentToLoad$ = this._loadComponent.asObservable();
  private panelLateralComponentRef!: PanelLateralComponent;

  constructor(private detectaUIService: DeteccionCambiosService) {
  }

  public setTitulo(titulo: string): void {
    this.panelLateralComponentRef.titulo = titulo;
    this.detectaUIService.setActualizaUI();
  }

  public setPanelLateralComponentRef(ref: PanelLateralComponent): void {
    this.panelLateralComponentRef = ref;
    this.detectaUIService.setActualizaUI();
  }

  public open() {
    this.isOpenSubject.next(true);
    this.detectaUIService.setActualizaUI();
  }

  public close() {
    this.isOpenSubject.next(false);
    this.detectaUIService.setActualizaUI();
  }

  public toggle() {
    this.isOpenSubject.next(!this.isOpenSubject.value);
    if (!this.isOpenSubject.value) {
      this.panelLateralComponentRef.clear();
      this.detectaUIService.setActualizaUI();
    }
  }

  public loadComponent(component: Type<any>) {
    this.detectaUIService.setActualizaUI();
    return this.panelLateralComponentRef.loadComponent(component);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelLateralComponent } from './panel-lateral.component';



@NgModule({
  declarations: [
    PanelLateralComponent
  ],
  exports: [
    PanelLateralComponent
  ],
  imports: [
    CommonModule
  ]
})
export class PanelLateralModule { }

import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true
    }
  ]
})
export class CustomSelectComponent implements ControlValueAccessor {
  public _elementos = [] as any[];
  public _elementosOriginales = [] as any[];
  public _elementoSelect: any = undefined;
  public _propiedad: string = 'id';
  public _etiqueta: string = 'descripcion';
  public _opcionVacia: string = 'Todos';
  public _opcionVaciaSeleccionada: boolean = false;
  public _mostrarOpcionVacia: boolean = true;
  public opened = false;
  private _valorElementoInicial: any;
  private value: any;
  private _orderBy = this._etiqueta;
  protected _busqueda = false;
  public busquedaTexto: string = '';

  @Input() set valorElementoInicial(value: any) {
    this._valorElementoInicial = value;
  }

  @Output() public elementoSeleccionado: EventEmitter<any> = new EventEmitter<any>();
  @Input() formControlName: string = '';
  @Input() disabled: boolean = false;

  @Input() set orderBy(value: string) {
    this._orderBy = value;
    this.ordenarElementos();
  }

  @Input() set elementos(value: any[]) {
    this._elementos = value;
    this._elementosOriginales = value;
    if (value.length > 0 && this._valorElementoInicial) {
      this.setElementoSelect(this._valorElementoInicial);
    }
    this.ordenarElementos()
  }

  @Input() set elementoSelect(value: any) {
    if (!value) {
      this._elementoSelect = null;
      this._opcionVaciaSeleccionada = true;
      return;
    }
    this.setElementoSelect(value);
  }

  @Input() set propiedad(value: string) {
    this._propiedad = value;
  }

  @Input() set etiqueta(value: string) {
    this._etiqueta = value;
  }

  @Input() set opcionVacia(value: string) {
    this._opcionVacia = value;
  }

  @Input() set mostrarOpcionVacia(value: boolean) {
    this._mostrarOpcionVacia = value;
  }

  @Input() set busqueda(value: boolean) {
    this._busqueda = value;
  }

  private onChange: any = () => {
  };
  private onTouched: any = () => {
  };

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  public ngOnInit(): void {
  }

  writeValue(value: any): void {
    this.value = value;
    this._opcionVaciaSeleccionada = !value;
    this.setElementoSelect(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public toogleElementos(): void {
    this.opened = !this.opened;
  }

  public seleccionarElemento(elemento: any) {
    this.busquedaTexto = '';
    this.buscar();
    if (elemento === null) {
      this._elementoSelect = null;
      this._opcionVaciaSeleccionada = true;
      this.onChange(null);
      this.value = null;
      this.toogleElementos();
      return;
    }
    this._opcionVaciaSeleccionada = false;
    this._elementoSelect = elemento;
    this.onChange(elemento[this._propiedad]);
    this.value = elemento[this._propiedad];
    this.toogleElementos();
  }

  private setElementoSelect(value: any): void {
    this._elementoSelect = this._elementos.find((elemento) => {
      return elemento[this._propiedad] === value;
    });

    this.value = this._elementoSelect ? this._elementoSelect[this._propiedad] : undefined;
    this._opcionVaciaSeleccionada = !this.value;
  }

  private ordenarElementos(): void {
    if (this._elementos.length === 0) {
      return;
    }
    this._elementos.sort((a, b) => {
      const aId = a.id;
      const bId = b.id;

      if (aId === -1 && bId !== -1) return -1;
      if (bId === -1 && aId !== -1) return 1;
      if (aId === null && bId !== null) return -1;
      if (bId === null && aId !== null) return 1;

      return a[this._orderBy].localeCompare(b[this._orderBy]);
    });
  }



  protected buscar() {
    this.elementos = [...this._elementosOriginales];
    if (this.busquedaTexto) {
      this._elementos = this._elementos.filter((elemento) => {
        return elemento[this._etiqueta].toLowerCase().includes(this.busquedaTexto.toLowerCase());
      });
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {Carpeta} from "../../interfaces/carpeta";

@Injectable({
  providedIn: 'root'
})
export class BorrarCarpetasService {

  private readonly carpetaUrl: string;

  constructor(private httpClient: HttpClient) {
    this.carpetaUrl = environment.apiUrl + '/shared/carpeta/';
  }

  public __invoke(carpetaId: string): Observable<Carpeta> {
    return this.httpClient.delete<Carpeta>(this.carpetaUrl + carpetaId);
  }
}

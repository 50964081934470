import {Component, Input, Sanitizer} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})
export class AyudaComponent {
  public _rutaWordpress!: string;

  @Input()
  public set rutaWordpress(ruta: string | undefined) {
    if (ruta) {
      this._rutaWordpress = ruta;
    }
  };

  constructor(private sanitizer: DomSanitizer) {
  }

  public sanitizeUrl(_rutaWordpress: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(_rutaWordpress);
  }
}

<div class="relative z-10 hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true"
     id="{{identificadorModal}}">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="lg:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900"
                id="modal-title">{{'Editar carpeta' | translate}}</h3>
          </div>
        </div>
        <div class="grid grid-cols-1 mt-3 sm:ml-4 sm:mt-0" [formGroup]="editarCarpetaForm">
          <div class="my-2">
            <label for="descripcion"
                   class="block text-sm font-medium leading-6 text-gray-900">{{'Título' | translate}}</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <input type="text" formControlName="descripcion" id="descripcion"
                     [ngClass]="editarCarpetaForm.get('descripcion')!.errors ? 'ring-1 ring-inset ring-red-300' : 'ring-1 ring-inset ring-gray-300'"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                     *ngIf="editarCarpetaForm.get('descripcion')!.errors">
                  <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"/>
                </svg>
              </div>
            </div>
            <p class="mt-2 text-sm text-red-600" *ngIf="editarCarpetaForm.get('descripcion')!.errors">
              {{editarCarpetaForm.get('descripcion')!.errors?.[0]}}
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="editarCarpeta()" appLoadingButton [loading]="loading"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto">
            {{'Guardar' | translate}}
          </button>
          <button type="button" (click)="closeModal()"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
            {{'Cancelar' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {RutaAyuda} from "./ruta-ayuda";

@Injectable({
  providedIn: 'root'
})
export class ObtenerAyudaService {
  private readonly sharedUrl: string;

  constructor(private httpclient: HttpClient) {
    this.sharedUrl = environment.apiUrl + '/shared/ayuda';
  }

  public __invoke(): Observable<RutaAyuda[]> {
    return this.httpclient.get<RutaAyuda[]>(this.sharedUrl);
  }
}

import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ImagenCorporativa} from '../interfaces/imagen-corporativa';

@Injectable({
  providedIn: 'root'
})
export class ObtenerImagenCorporativaService {

  private readonly publicasUrl: string;
  public imagenCorporativa!: ImagenCorporativa;
  private httpClient: HttpClient;

  constructor(private readonly httpHandler: HttpBackend) {
    this.httpClient = new HttpClient(httpHandler);
    this.publicasUrl = environment.apiUrl + '/guest/configuracion-cliente/imagen-corporativa';
  }

  public __invoke(): Promise<ImagenCorporativa> {
    return new Promise<ImagenCorporativa>((resolve, reject) => {
      this.httpClient.get<ImagenCorporativa>(this.publicasUrl).subscribe({
        next: (imagenCorporativa: ImagenCorporativa) => {
          this.imagenCorporativa = imagenCorporativa;
          resolve(imagenCorporativa);
        }, error: (errorResponse) => {
          reject(errorResponse);
        }
      });
    });
  }

  public obtenerImagenCorporativa(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return undefined;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    return key.reduce((acc: any, current: string) => acc && acc[current], this.imagenCorporativa);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSelectComponent } from './custom-select.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from "@angular/forms";



@NgModule({
    declarations: [
        CustomSelectComponent
    ],
    exports: [
        CustomSelectComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule
    ]
})
export class CustomSelectModule { }

import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentRef, EventEmitter,
  HostListener,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {PanelLateralService} from "./panel-lateral.service";
import {DeteccionCambiosService} from "../../../../infrastructure/services/deteccion-cambios.service";

@Component({
  selector: 'app-panel-lateral',
  templateUrl: './panel-lateral.component.html',
  styleUrls: ['./panel-lateral.component.css']
})
export class PanelLateralComponent implements AfterViewInit {
  @ViewChild('container', {read: ViewContainerRef, static: true}) container!: ViewContainerRef;
  public titulo = '';
  @Output() cargado = new EventEmitter<boolean>();

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.panelLateralService.close();
    }
  }

  constructor(public panelLateralService: PanelLateralService, private cdr: ChangeDetectorRef, private detectaUIService: DeteccionCambiosService) {
  }

  public ngOnInit(): void {
    this.panelLateralService.setPanelLateralComponentRef(this);
  }

  public ngAfterViewInit() {
    this.panelLateralService.componentToLoad$.subscribe({
      next: (component) => this.loadComponent(component)
    });
  }
  public componenteCargado(){
    this.cargado.emit(true);
  }

  public clear() {
    this.container.clear();
  }

  public loadComponent(component: Type<any>): ComponentRef<any> {
    this.container.clear();
    const componentRef = this.container.createComponent(component);
    this.panelLateralService.open();
    this.detectaUIService.getActualizaUI$().subscribe(() => {
      this.cdr.markForCheck();
      setTimeout(() => {
        this.cargado.emit(true);
      });
    });
    return componentRef;
  }
}

<div class="relative mt-2">
  <button type="button" (click)="toogleElementos()" [disabled]="disabled"
          [ngClass]="{'opacity-50': disabled, '': !disabled}"
          class="relative w-full c-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
          aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
    <span class="inline-flex w-full truncate">
        <span>{{ _elementoSelect ? _elementoSelect[_etiqueta] : _opcionVacia | translate }}</span>
    </span>
    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                  clip-rule="evenodd"/>
        </svg>
    </span>
  </button>
  <div class="relative">
    <ul [ngClass]="{'': opened, 'hidden': !opened}"
        class="hidden absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1" role="listbox" aria-labelledby="listbox-label" id="mensajesSistema">
      <li class="text-gray-900 relative c-pointer select-none py-1 px-1"
          *ngIf="_busqueda">
        <input [(ngModel)]="busquedaTexto" (input)="buscar()" placeholder="Buscar..."
               class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      </li>
      <div class="overflow-auto max-h-32">
        <li class="text-gray-900 relative overflow-auto c-pointer select-none py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
            *ngIf="_mostrarOpcionVacia && !busquedaTexto"
            role="option" (click)="seleccionarElemento(null)">
          <span class="font-normal block truncate">{{ _opcionVacia }}</span>
          <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                *ngIf="_elementoSelect === null || _elementoSelect=== undefined">
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"/>
          </svg>
          </span>
        </li>
        <ng-container *ngFor="let elemento of _elementos">
          <li class="text-gray-900 relative c-pointer select-none py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
              id="listbox-option-0"
              role="option" (click)="seleccionarElemento(elemento)">
            <span class="font-normal block truncate">{{ elemento[_etiqueta] }}</span>
            <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                  *ngIf="_elementoSelect && _elementoSelect[_propiedad] === elemento[_propiedad]">
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"/>
          </svg>
          </span>
          </li>
        </ng-container>
      </div>
    </ul>
  </div>
</div>


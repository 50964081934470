<div class="relative" aria-labelledby="modal-title" role="dialog" aria-modal="true"
     style="z-index: 99 !important;"
     [ngClass]="(modalService.isOpen$ | async) ? '' : 'hidden'">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="lg:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left {{options.size}} shadow-xl transition-all ">
        <div class="sm:flex sm:items-start pb-3">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900"
                id="modal-title">{{ titulo }}</h3>
          </div>
        </div>
        <ng-template #container></ng-template>
      </div>
    </div>
  </div>
</div>



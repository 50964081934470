import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Boton} from './boton';

@Component({
  selector: 'app-grupo-botones',
  templateUrl: './grupo-botones.component.html',
  styleUrls: ['./grupo-botones.component.css']
})
export class GrupoBotonesComponent {
  public _botones: Boton[] = [];
  public botonSeleccionado!: Boton;

  @Output() botonSeleccionadoEvento: EventEmitter<Boton> = new EventEmitter<Boton>();

  @Input()
  set botones(botones: Boton[]) {
    this._botones = botones;
    this.configurarBotones();
  }


  private configurarBotones(): void {
    this._botones.forEach((boton, index) => {
      boton.clases = '';

      if (boton.valorPorDefecto) {
        this.botonSeleccionado = boton;
        this.botonSeleccionadoEvento.emit(boton);
      }

      if (index === 0) {
        boton.clases += ' relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-inset hover:bg-gray-50 focus:z-10';
      } else if (index === this._botones.length - 1) {
        boton.clases += ' relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-inset hover:bg-gray-50 focus:z-10';
      } else {
        boton.clases += ' relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-inset hover:bg-gray-50 focus:z-10';
      }
    });
  }

  public setBotonSeleccionado(boton: Boton): void {
    this.restablecerBotonesValorPorDefecto(boton);
    this.botonSeleccionado = boton;
    this.botonSeleccionadoEvento.emit(boton);
  }

  private restablecerBotonesValorPorDefecto(botonSeleccionado: Boton) {
    this._botones.forEach(boton => {
      boton.valorPorDefecto = boton === botonSeleccionado;
    });
  }
}

import { Component } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ModalBaseComponent} from "../../../../infrastructure/modules/modal-base/modal-base.component";
import {ValidationService} from "../../../../infrastructure/services/validation.service";
import {CrearCarpetaService} from "./crear-carpeta.service";
import {Carpeta} from "../../interfaces/carpeta";

export interface CrearCarpetaFG {
  descripcion: string | null;
}
@Component({
  selector: 'app-modal-crear-carpeta',
  templateUrl: './modal-crear-carpeta.component.html',
  styleUrls: ['./modal-crear-carpeta.component.css']
})
export class ModalCrearCarpetaComponent extends ModalBaseComponent {

  override identificadorModal: string = 'modal-crear-chat' + Math.random().toString(36).substring(7);
  public crearCarpetaForm = {} as FormGroup;
  public loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private validationService: ValidationService,
              private crearCarpetaService: CrearCarpetaService,
  ) {
    super();
    this.buildForm();
  }
  private buildForm(): void {
    this.crearCarpetaForm = this.formBuilder.group<CrearCarpetaFG>({
      descripcion: null,
    });
  }

  crearCarpeta(): void {
    this.loading = true;
    this.crearCarpetaService.__invoke(this.crearCarpetaForm.value).subscribe({
      next: (carpeta) => {
        this.buildForm();
        this.confirmAction(carpeta);
        this.loading = false;
      }, error: (errorResponse) => {
        this.loading = false;
        this.validationService.validateForm(this.crearCarpetaForm, errorResponse.error.errors);
      }
    })
  }

  override confirmAction(carpeta: Carpeta): void {
    this.actionConfirmedSubject.next(carpeta);
    this.closeModal();
  }
}

<span class="isolate flex w-full rounded-md shadow-sm" *ngIf="_botones.length > 0">
  <ng-container *ngFor="let boton of _botones">
    <button
      class="flex-grow w-0 justify-center {{ boton.clases }}"
      [ngClass]="{
        ' bg-gray-200': !boton.valorPorDefecto && boton.deshabilitado,
        ' bg-indigo-600 text-white ring-indigo-300 ring-1' : boton.valorPorDefecto,
        ' bg-white text-gray-900 ring-gray-300 hover:bg-gray-50 ring-1': !boton.valorPorDefecto && !boton.deshabilitado
        }"
      [disabled]="boton.deshabilitado"
      title="{{ boton.deshabilitado ? 'Opción no disponible' : boton.texto}}"
      (click)="setBotonSeleccionado(boton)">
      {{ boton.texto }}
    </button>
  </ng-container>
</span>

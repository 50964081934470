import {Injectable} from '@angular/core';
import PusherJS from 'pusher-js';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PusherjsService {

  public headers: any;
  private pusher!: PusherJS;

  public execute(): PusherJS | undefined {
    PusherJS.log = function (msg) {
    };
    if (!this.pusher) {
      this.pusher = new PusherJS('70a374fd671ceb78539d', {
        wsHost: environment.websockets,
        enableStats: true,
        forceTLS: environment.encrypted,
        cluster: 'eu',
        enabledTransports: ['ws', 'wss'],
        authEndpoint: environment.apiUrl + '/auth/pusherjs-login',
        authTransport: 'ajax',
      });
    }
    return this.pusher;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-pantalla-completa',
  templateUrl: './pantalla-completa.component.html',
  styleUrls: ['./pantalla-completa.component.css']
})
export class PantallaCompletaComponent {

}

import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ObtenerImagenCorporativaService} from '../../services/obtener-imagen-corporativa.service';

export function inicializarImagenCorporativaFactory(env: ObtenerImagenCorporativaService): any {
  const promise = env.__invoke().then(() => {
  });
  return () => promise;
}

@NgModule({
  declarations: [],
  imports: []
})

export class ImagenCorporativaModule {
  static forRoot() {
    return {
      ngModule: ImagenCorporativaModule,
      providers: [
        ObtenerImagenCorporativaService,
        {
          provide: APP_INITIALIZER,
          useFactory: inicializarImagenCorporativaFactory,
          deps: [ObtenerImagenCorporativaService],
          multi: true
        }
      ]
    }
  }
}

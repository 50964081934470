<ul role="list" class="-mx-2 space-y-1">
  <li class="flex border-b pb-3 justify-between">
    <div (click)="abrirModalNuevoChat()" title="{{'Nuevo chat' | translate}}"
         class="w-4/5 hover:bg-indigo-100 text-indigo-600 group flex gap-x-1 rounded-md p-3 text-sm leading-6 font-semibold c-pointer border shadow-sm hover:border-indigo-500">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
           stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
      </svg>
      {{'Nuevo chat' | translate}}
    </div>
    <div (click)="abrirModalNuevaCarpeta()" title="{{'Nueva carpeta' | translate}}"
         class="hover:bg-indigo-100 text-indigo-600 group rounded-md p-3 text-sm leading-6 font-semibold c-pointer border shadow-sm  hover:border-indigo-500">
      <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 18 18">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M7 11h4m-2 2V9M2 5h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm5.443-4H2a1 1 0 0 0-1 1v3h9.943l-2.7-3.6a1 1 0 0 0-.8-.4Z"/>
      </svg>
    </div>
  </li>
  <li>
    <div [formGroup]="buscarConversacionesForm">
      <div class="mt-3 mb-6">
        <input type="text" formControlName="asunto" id="asunto"
               placeholder="{{'Buscar conversación...' | translate}}"
               class="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      </div>
    </div>
  </li>
  <app-carpetas #carpetasComponent
                [descripcion]="buscarConversacionesForm.get('asunto')?.value"
                [esDispositivoMovil]="_esDispositivoMovil">
  </app-carpetas>
</ul>
<app-modal-crear-carpeta #modalCrearCarpeta></app-modal-crear-carpeta>

import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {AccountInfo} from '@azure/msal-common';

export const usuarioAzureActivoGuard: CanActivateFn = (route, state) => {
  const msalService: MsalService = inject(MsalService);
  const activeAccount: AccountInfo | null = msalService.instance.getActiveAccount();
  const router = inject(Router);
  if (activeAccount === null) {
    router.navigate(['/login']).then(r => {
    });
  }
  return activeAccount !== null;
};

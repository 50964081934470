import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ForzarRedireccionService {

  constructor(private router: Router) {

  }

  async __invoke(uri: string) {
    await this.router.navigate(['/']);
    await this.router.navigate([uri]);
  }
}

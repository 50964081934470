import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {AccountInfo} from '@azure/msal-common';

@Injectable({
  providedIn: 'root'
})
export class ObtenerUsuarioService {

  constructor(private msalService: MsalService) {
  }

  public __invoke(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }
}

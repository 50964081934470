import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Carpeta} from "../interfaces/carpeta";

@Injectable({
  providedIn: 'root'
})
export class ListarCarpetasService {

  private readonly carpetasUrl: string;

  constructor(private httpClient: HttpClient) {
    this.carpetasUrl = environment.apiUrl + '/shared/carpetas/';
  }

  public __invoke(): Observable<Carpeta[]> {
    return this.httpClient.get<Carpeta[]>(this.carpetasUrl);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {PerfilConversacionAsignado} from '../interfaces/perfil-conversacion';

@Injectable({
  providedIn: 'root'
})
export class ObtenerPerfilesAsignadosGrupoUsuariosService {

  private readonly grupoUsuariosUrl: string;

  constructor(private httpClient: HttpClient) {
    this.grupoUsuariosUrl = environment.apiUrl + '/shared/grupo-usuarios';
  }

  public __invoke(id: string): Observable<PerfilConversacionAsignado[]> {
    return this.httpClient.get<PerfilConversacionAsignado[]>(`${this.grupoUsuariosUrl}/${id}/perfiles-conversacion-asignados`);
  }
}

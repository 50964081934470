import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ModelosActivos} from '../interfaces/modelos-activos';

@Injectable({
  providedIn: 'root'
})
export class ObtenerConfiguracionesActivasModelosService {

  private readonly configuracionClienteUrl: string;

  constructor(private httpClient: HttpClient) {
    this.configuracionClienteUrl = environment.apiUrl + '/shared/configuracion-cliente/modelos-activos';
  }

  public __invoke(): Observable<ModelosActivos> {
    return this.httpClient.get<ModelosActivos>(this.configuracionClienteUrl );
  }
}

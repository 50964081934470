import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ObtenerImagenCorporativaService} from './application/public/services/obtener-imagen-corporativa.service';
import {ImagenCorporativa} from './application/public/interfaces/imagen-corporativa';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from "@angular/router";
import {MsalBroadcastService} from '@azure/msal-angular';
import {ModalService} from "./application/shared/modules/modal/modal.service";
import {DeteccionCambiosService} from "./infrastructure/services/deteccion-cambios.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private imagenCorportativa: ImagenCorporativa;

  constructor(private obtenerImagenCorporativaService: ObtenerImagenCorporativaService,
              private title: Title,
              private deteccionCambiosService: DeteccionCambiosService,
              private cdRef: ChangeDetectorRef,
              private msalBroadcastService: MsalBroadcastService,
              private router: Router) {
    this.imagenCorportativa = this.obtenerImagenCorporativaService.imagenCorporativa;
    this.configurarAplicacion();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !event.url.includes('/chat')) {
        this.title.setTitle(this.imagenCorportativa.titulo);
      }
    });
  }

  public ngOnInit(): void {
    this.deteccionCambiosService.getActualizaUI$().subscribe(() => {
      this.refresca();
    });
    this.msalBroadcastService.msalSubject$.subscribe({
      next: async (msalSubject) => {
        if (msalSubject.eventType === 'msal:acquireTokenFailure') {
          sessionStorage.clear();
          localStorage.clear();
          await this.router.navigate(['/login']);
        }
      },
    });
  }

  private configurarAplicacion(): void {
    const favIcon: HTMLLinkElement | null = document.querySelector('#favIcon');
    favIcon!.href = this.imagenCorportativa.favicon;
  }

  refresca() {
    setTimeout(() => {
      this.cdRef.detectChanges();
    });
  }
}

export const environment = {
  sentryDsn: 'https://80d1a0c0a6124b9a9793e5c8a2c1b941@sentry.lunia.es/16',
  apiUrl: '/api',
  msalConfig: {
    auth: {
      clientId: '8441ebd0-4ec1-4b4f-b675-eb81cb872a67',
      redirectUri: '/',
      authority: 'https://login.microsoftonline.com/7bc317d8-8b33-4c44-aa12-5e1e382f3590',
      postLogoutRedirectUri: '/login'
    }
  },
  apiConfig: {
    scopes: ['user.read', 'openid', 'profile', 'offline_access', 'email'],
    responseType: 'code',
    uri: 'https://graph.microsoft.com/v1.0/me'
  },
  websockets: window.location.hostname,
  websocketsTransport: 'wss',
  websocketsPath: 'app',
  encrypted: true,
  production: true,
}

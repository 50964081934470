import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {BuscarConversacionesFG} from "./conversaciones.component";
import {Observable} from "rxjs";
import {ConversacionListado} from "../../interfaces/conversacion";

@Injectable({
  providedIn: 'root'
})
export class BuscarConversacionesPorCarpetaService {

  private readonly conversacionUrl: string;

  constructor(private httpClient: HttpClient) {
    this.conversacionUrl = environment.apiUrl + '/shared/conversacion/';
  }

  public __invoke(carpetaId:string, parametros: BuscarConversacionesFG): Observable<ConversacionListado[]> {
    return this.httpClient.post<ConversacionListado[]>(this.conversacionUrl + carpetaId + '/buscar', parametros);
  }
}

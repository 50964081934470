import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObtenerGrupoAsignadoService {

  private readonly grupoAsignadoURL: string;

  constructor(private httpClient: HttpClient) {
    this.grupoAsignadoURL = environment.apiUrl + '/shared/grupo-asignado';
  }

  public __invoke(): Observable<GrupoAsignado> {
    return this.httpClient.get<GrupoAsignado>(this.grupoAsignadoURL);
  }
}

interface GrupoAsignado {
  id: string;
  nombre: string;
  perfil_conversacion_id:string | null;
}

import {Component, Input} from '@angular/core';
import {DatosModalConfirmacion} from './datos-modal-confirmacion';
import {ModalBaseComponent} from '../../../../infrastructure/modules/modal-base/modal-base.component';

@Component({
  selector: 'app-modal-confirmacion-accion',
  templateUrl: './modal-confirmacion-accion.component.html',
  styleUrls: ['./modal-confirmacion-accion.component.css']
})
export class ModalConfirmacionAccionComponent extends ModalBaseComponent {

  override identificadorModal: string = 'action-confirmation' + Math.random().toString(36).substring(7);
  public _actionConfirmationData = {} as DatosModalConfirmacion;

  @Input()
  set actionConfirmationData(actionConfirmationData: DatosModalConfirmacion) {
    this._actionConfirmationData = actionConfirmationData;
  }
}

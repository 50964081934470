import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {DeteccionCambiosService} from "../services/deteccion-cambios.service";

@Directive({
  standalone: true,
  selector: '[appLoadingButton]'
})
export class LoadingButtonDirective implements OnInit, OnChanges {
  @Input() loading = false;
  public spinSpan: any;
  public button: any;
  span = '<span class="hidden"><svg class="animate-spin ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">\n' +
    '  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>\n' +
    '  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>\n' +
    '</svg></span>';
  private teniaFlex = false;
  constructor(private el: ElementRef,
              private detectaUI: DeteccionCambiosService,
              private renderer: Renderer2) { }


  ngOnInit(): void {
    this.button = this.el.nativeElement;
    this.button.insertAdjacentHTML('beforeend', this.span);
    if(this.button.classList.contains('flex')) {
      this.teniaFlex = true;
    }
    this.spinSpan = this.button.querySelector('span');
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.isViewReady()) {
      this.changeButtonStatus(changes['loading'].currentValue);
    }
  }

  public changeButtonStatus(status: boolean): void {
    if (status) {
      if(!this.teniaFlex) {
        this.renderer.addClass(this.button, 'flex');
      }
      this.renderer.removeClass(this.spinSpan, 'hidden');
      this.renderer.setAttribute(this.button, 'disabled', 'true');
      this.renderer.addClass(this.button, 'disabled:opacity-50');

    } else {
      if(!this.teniaFlex) {
        this.renderer.removeClass(this.button, 'flex');
      }
      this.renderer.addClass(this.spinSpan, 'hidden');
      this.renderer.removeAttribute(this.button, 'disabled');
      this.renderer.removeClass(this.button, 'disabled:opacity-50');
      this.detectaUI.setActualizaUI();
    }
  }
  private isViewReady() {
    return !!(this.spinSpan && this.button);
  }
}

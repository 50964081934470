import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingButtonDirective} from "../../../../infrastructure/directives/loading-button.directive";
import {GrupoBotonesModule} from "../grupo-botones/grupo-botones.module";
import {ModalEditarCarpetaComponent} from "./modal-editar-carpeta.component";


@NgModule({
  declarations: [
    ModalEditarCarpetaComponent
  ],
  exports: [
    ModalEditarCarpetaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    LoadingButtonDirective,
    GrupoBotonesModule
  ]
})
export class ModalEditarCarpetaModule { }

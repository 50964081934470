import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompruebaConversacionTieneMesajesService {

  private readonly conversacionUrl: string;

  constructor(private httpClient: HttpClient) {
    this.conversacionUrl = environment.apiUrl + '/shared/conversacion/';
  }

  public __invoke(conversacionId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.conversacionUrl + conversacionId + '/tiene-mensajes');
  }
}

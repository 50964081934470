import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CarpetasComponent} from "./carpetas.component";
import {ModalEditarConversacionModule} from "../modal-editar-conversacion/modal-editar-conversacion.module";
import {ModalConfirmacionAccionModule} from "../modal-confirmacion-accion/modal-confirmacion-accion.module";
import {ModalEditarCarpetaModule} from "../modal-editar-carpeta/modal-editar-carpeta.module";

@NgModule({
  declarations: [
    CarpetasComponent
  ],
  exports: [
    CarpetasComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ModalEditarConversacionModule,
    ModalConfirmacionAccionModule,
    ModalEditarCarpetaModule
  ]
})
export class CarpetasModule { }

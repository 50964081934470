import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {Conversacion} from '../../interfaces/conversacion';
import {EditarConversacionFG} from './modal-editar-conversacion.component';

@Injectable({
  providedIn: 'root'
})
export class EditarConversacionService {

  private readonly conversacionUrl: string;

  constructor(private httpClient: HttpClient) {
    this.conversacionUrl = environment.apiUrl + '/shared/conversacion/';
  }

  public __invoke(conversacionId: string, parametros: EditarConversacionFG): Observable<Conversacion> {
    return this.httpClient.put<Conversacion>(this.conversacionUrl + conversacionId, parametros);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {BuscarConversacionesFG} from './conversaciones.component';
import {Observable} from 'rxjs';
import {Conversacion} from '../../interfaces/conversacion';

@Injectable({
  providedIn: 'root'
})
export class ObtenerConversacionService {

  private readonly conversacionUrl: string;

  constructor(private httpClient: HttpClient) {
    this.conversacionUrl = environment.apiUrl + '/shared/conversacion/';
  }

  public __invoke(conversacionId: string): Observable<Conversacion> {
    return this.httpClient.get<Conversacion>(this.conversacionUrl + conversacionId);
  }
}
